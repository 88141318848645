@import '../../styles/variables.scss';

.analyticsfilter{
    margin-top: 10px;
    margin-left: 10px;
}

@media(max-width: 830px){
    .analyticsfilter{
        margin-left: 0px;
    }
}

.analyticsfilter__row{
    display: flex;

    &-pit{
        background-position: 70px 48%;
    }

    &-period{
        background-position: 110px 48%;
    }

    &-company{
        background-position: 185px 48%;
    }
}

.selector{
    font-family: $font;
    text-align: center;
    color: $primary-color;
    font-size: 14px;
    line-height: 17.5px;
    padding: 10px 10px;
    padding-right: 40px;
    background-color: $background;
    border-radius: 10px;
    margin-right: 10px;
    background-image: url('../../ui/images/Profile_button.png');
    background-repeat: no-repeat;
    
}

select::-ms-expand{
    display: none;
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;

}

@media(max-width: 390px){
    .analyticsfilter{
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: 100%;
    }
}