@import '../../styles/variables.scss';

.ridelistelement{
    margin-top: 10px;
    border-bottom: 1px solid rgba($secondary-color, $alpha: 0.2);
}

.highlighted{
    background-color: $background;
}

.ridelistelement_sctucture{
    line-height: normal;
    margin-left: 10px;
    display: flex;
    
    justify-content: space-between;

    &-id{
        width: 20px;
    }

    &-number{
        width: 85px;
    }

    &-entry{
        width: 70px;
    }

    &-exit{
        width: 85px;
    }

    &-duration{
        width: 120px;
    }

    &-weight{
        width: 70px;
    }

    &-volume{
        width: 90px;
        cursor: pointer;
        
    }

    &-road{
        width: 65px;
        
    }

    &-photos{
       display: flex;
       
    }
}

.ridelistelement_sctucture-volume-preview{
    position: absolute;
    left: 40%;
    cursor: pointer;
}

.ridelistelement_sctucture-volume-preview-close{
    background-color: white;
    padding: 2px;
    top: 2px;
    position: relative;
    cursor: pointer;
    left: 485px;
}

.idelistelement__adaptive-sctucture-row-volume{
    margin-top: 10px;
    margin-bottom: 10px;
}

@media(max-width: 830px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.7);
        position: absolute;
        left: 20%;
    }
}

@media(max-width: 620px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.7);
        position: absolute;
        left: 10%;
    }
}

@media(max-width: 520px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.7);
        position: absolute;
        left: 0%;
    }
}

@media(max-width: 460px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.5);
        position: absolute;
        left: -5%;
    }
}

@media(max-width: 380px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.5);
        position: absolute;
        left: -20%;
    }
}

@media(max-width: 330px){
    .ridelistelement_sctucture-volume-preview{
        transform: scale(0.4);
        position: absolute;
        left: -30%;
    }
}

.data{
    font-family: $font;
    color: $primary-color;
    font-size: 14px;
    
}

.ridelistelement_sctucture-photo{
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 90px;
    height: 60px;
    cursor: pointer;
    border-radius: 10px;
}

.popup__image{
    display: flex;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 27%;
    left: 32%;
    right: 0;
    text-align: center;
}


.ridelistelement__sctucture-photo-popup-icon{
    height: 30px;
    position: relative;
    
    z-index: 130;
    top: 20px;
    left: 730px;
    cursor: pointer;
}


.ridelistelement__sctucture-photo-popup{
    width: 750px;
    height: 480px;
    
    z-index: 100;
    top: 27%;
    left: 32%;
    border-radius: 30px;
}

@media(max-width: 1024px){
    .popup__image{
        left: 50%;
    }
}

@media(max-width: 830px){
    .popup__image{
        left: 25%;
    }
}

@media(max-width: 570px){
    .popup__image{
        left: 20%;
    }
}

@media(max-width: 450px){
    .popup__image{
        left: 10%;
    }
}

@media(max-width: 350px){
    .popup__image{
        left: 0;
    }
}

.ridelistelement_sctucture-road-button{
    background-color: $blue-color;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
}

.ridelistelement_sctucture-road-popup{
    background-color: $background;
    padding:  20px;
    position: absolute;
    left: 40%;
    top: 20%;
    border-radius: 20px;
}

.ridelistelement_sctucture-road-popup-structure{
    display: flex;
    flex-direction: column;
}

.ridelistelement_sctucture-road-popup-photo{
    
}

.ridelistelement_sctucture-road-popup-buttons{
    margin-top: 30px;
    align-self: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ridelistelement_sctucture-road-popup-button{
    background-color: $blue-color;
    color: white;
    font-family: $font;
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 10px;
    
}


.ridelistelement_sctucture-entry-main{
    line-height: normal;
}
.ridelistelement_sctucture-entry-side{
    color: $secondary-color;
    line-height: normal;
}

@media(max-width: 1320px){
    .ridelistelement_sctucture-photos{
        display: flex;
    }
}

@media(max-width: 1200px){
    .ridelistelement_sctucture{
        gap: 0px;
    }

    
}

@media(max-width: 1024px){
    .ridelistelement_sctucture{
        display: none;
    }
}

///....................ADAPTIVE......................///

.ridelistelement__adaptive-sctucture-photo-popup-icon{
    
    position: relative;
    z-index: 130;
    top: 10px;
    left: 269px;
    cursor: pointer;
}

.ridelistelement__adaptive-sctucture-photo-popup{
    width: 299px;
    height: 188px;
    position: absolute;
    z-index: 100;
    left: 10px;
    border-radius: 10px;
}

.ridelistelement__adaptive-sctucture{
    display: none;
}

@media(max-width: 1024px){
    .ridelistelement__adaptive-sctucture{
        display: block;
        padding-right: 10px;
        padding-left: 10px;
    }
}

.idelistelement__adaptive-sctucture-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.idelistelement__adaptive-sctucture-row-id{
    &-title{

    }

    &-info{

    }
}

.idelistelement__adaptive-sctucture-row-number{
    &-title{

    }

    &-info{

    }
}

.idelistelement__adaptive-sctucture-row-weight{
    &-title{

    }

    &-info{

    }
}

.ridelistelement__adaptive-sctucture-date{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.ridelistelement__adaptive-sctucture-date-image{
    margin-right: 5px;
    margin-left: 5px;
}

.mainText{
    font-family: $font;
    font-size: 14px;
    line-height: normal;
    color: $primary-color;
}

.sideText{
    font-family: $font;
    font-size: 14px;
    line-height: normal;
    color: $secondary-color;
}

.ridelistelement__adaptive-sctucture-duration{
    font-family: $font;
    font-size: 14px;
    line-height: normal;
    color: $primary-color;
}

.ridelistelement__adaptive-sctucture-photos{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.ridelistelement__adaptive-sctucture-photo{
    width: 160px;
    height: 100px;
    border-radius: 10px;
}

@media(max-width: 350px){
    .ridelistelement__adaptive-sctucture-photo{
        width: 130px;
        height: 90px;
    }
}

.ridelistelement__adaptive-sctucture-photo:nth-child(1){
    margin-right: 10px;
}

.adaptiveTitle{
    font-family: $font;
    font-size: 10px;
    line-height: normal;
    color: $secondary-color;
}

.adaptiveData{
    font-family: $font;
    font-size: 18px;
    line-height: normal;
    color: $primary-color;
}